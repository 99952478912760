import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardTitle, Col, Container, Modal, Row, Table,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Loader from "../../components/Common/Loader";
import {connect} from "react-redux";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import {GetCustomers, GetCustomerUsers, getOffersDetails, getProjectList, getStockPointConfig, getStockPoints} from "../../store/actions";
import {AvField, AvForm} from "availity-reactstrap-validation";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import DatePicker from "react-date-picker";
import {find, isEmpty, sumBy} from "lodash";
import {getTenantAdmins} from "../../store/tenant/actions";
import {get, post} from "../../helpers/api_helper";
import {API_ERR_MSG} from "../../util/constants";
import {preventNumberInputScroll} from "../../util/helperFunctions";
import {isStockable} from "../../util/articleUtil";
import DropdownSelect from "../../components/Common/DropdownSelect";
import CustomerUserFormContainer from "../Customer/CustomerUserFormContainer";
import DeliveryAddressForm from "../Customer/DeliveryAddressForm";

const OrderFromOfferForm = (props) => {
    const {
        customerOptions, projects, customerUsers, tenantUserOptions,
        onGetCustomerOptions, onGetProjectOptions, onGetCustomerUsers,
        onGetTenantUsers, onGetOfferDetails, offerDetails, offerDetailsLoading,
        stockPoints, stockPointConfig, onGetStockPoints, onGetStockPointConfig,
        match: { params }
    } = props;

    const [customer, setCustomer] = useState(null)
    const [offerDate, setOfferDate] = useState(new Date());
    const [expireDate, setExpireDate] = useState(new Date());
    const [ourReference, setOurReference] = useState(null);
    const [deliveryTerms, setDeliveryTerms] = useState(null);
    const [paymentTerms, setPaymentTerms] = useState(null);
    const [stockPoint, setStockPoint] = useState(null);
    const [priceList, setPriceList] = useState(null);
    const [yourReference, setYourReference] = useState(null);
    const [project, setProject] = useState(null);
    const [deliveryReference, setDeliveryReference] = useState(null);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [deliveryAddressList, setDeliveryAddressList] = useState([]);
    const [paymentTermList, setPaymentTermList] = useState([]);
    const [deliveryTermList, setDeliveryTermList] = useState([]);
    const [articleRows, setArticleRows] = useState([]);
    const [wayOfDelivery, setWayOfDelivery] = useState(null);
    const [wayOfDeliveryList, setWayOfDeliveryList] = useState([]);
    const [yourOrderNumber, setYourOrderNumber] = useState(null);

    const [projectOptions, setProjectOptions] = useState([]);
    const [customerUserOptions, setCustomerUserOptions] = useState([]);

    const [shipping, setShipping] = useState(0);
    const [subTotal, setSubTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [total, setTotal] = useState(0)
    const [discount, setDiscount] = useState(0)


    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);

    const [showAddCustomerUserModal, setShowAddCustomerUserModal] = useState(false);
    const [showAddDeliveryAddressModal, setShowAddDeliveryAddressModal] = useState(false);
    const [deliveryAddressListLoading, setDeliveryAddressListLoading] = useState(false);

    let history = useHistory();
    let user = JSON.parse(localStorage.getItem("authUser"));

    let newArticleRow = {
        article: null,
        quantity: 0,
        price: 0,
        discount: 0,
        total: 0,
        stockPoint: stockPoint,
    }

    useEffect(() => {
        setBrandSettings(user.brandConfig);

        onGetCustomerOptions();
        onGetStockPoints();
        onGetStockPointConfig();
        onGetTenantUsers(user?.tenantId);
        onGetTermsOfPaymentList()
        onGetTermsOfDeliveryList();
        onGetWayOfDeliveries();

        setArticleRows([newArticleRow]);
        if(!params.id){
            setExpireDate(futureExpireDate())
        }

        if (params.id) {
            onGetOfferDetails(params.id);
        }
    }, [])

    useEffect(() => {
        if (customer) {
            onGetProjectOptions(user?.tenantId, customer.id);
            onGetCustomerUsers(customer.id);
            onGetDeliveryAddressList();
            setPriceList(customer.priceList)
        } else {
            setPriceList(null);
            setProjectOptions([]);
            setCustomerUserOptions([]);
        }
    }, [customer])

    useEffect(() => {
        setProjectOptions(projects)
    }, [projects])

    useEffect(() => {
        setProjectOptions(projects)
    }, [projects])

    useEffect(() => {
        setCustomerUserOptions(customerUsers)
    }, [customerUsers])

    useEffect(() => {
        if (customerUserOptions) {
            let defaultVal = null;
            if (params.id && offerDetails) {
                defaultVal = find(customerUserOptions, {
                    name: offerDetails.YourReference
                })
            }
            else {
                if (customer?.yourReference) {
                    defaultVal = find(customerUserOptions, {
                        name: customer?.yourReference
                    })
                }
                else if (customerUserOptions.length > 0) {
                    defaultVal = customerUserOptions[0]
                }
            }
            if (defaultVal) {
                setYourReference(defaultVal.id);
            } else if (params.id) {
                setYourReference(null);
            }
        }
    }, [customerUserOptions, customer, offerDetails])

    useEffect(() => {
        const u = find(tenantUserOptions, { id: user.uid })
        setOurReference(u ? u.id: null);
    }, [tenantUserOptions])

    useEffect(() => {
        if (articleRows) {
            const st = sumBy(articleRows, ar => ar.total)
            const tx = sumBy(articleRows, ar => ar.article?.vat ? ar.article.vat * ar.total / 100 : 0)
            const d = sumBy(articleRows, ar => ar.article?.salesPrice ? (ar.article?.salesPrice * ar.quantity) - ar.total : 0)
            setSubTotal(st);
            setTax(tx);
            setDiscount(d);
            setTotal(st + tx);
        }
    }, [articleRows])

    useEffect(() => {
        if (paymentTermList) {
            let defaultVal = null;
            if (offerDetails && params.id) {
                defaultVal = find(paymentTermList, {
                    Code: offerDetails.TermsOfPayment
                })
            }
            else {
                if (customer?.termsOfPayment) {
                    defaultVal = find(paymentTermList, {
                        Code: customer?.termsOfPayment
                    })
                }
                else if (paymentTermList.length > 0) {
                    defaultVal = paymentTermList[0]
                }
            }
            if (defaultVal) {
                setPaymentTerms(defaultVal.Code);
            } else if (params.id) {
                setPaymentTerms(null);
            }
        }
    }, [paymentTermList, customer, offerDetails]);

    useEffect(() => {
        if (deliveryTermList) {
            let defaultVal = null;
            if (offerDetails && params.id) {
                defaultVal = find(deliveryTermList, {
                    Code: offerDetails.TermsOfDelivery
                })
            }
            else {
                if (customer?.termsOfDelivery) {
                    defaultVal = find(deliveryTermList, {
                        Code: customer?.termsOfDelivery
                    })
                } else if (deliveryTermList.length > 0) {
                    defaultVal = deliveryTermList[0]
                }
            }
            if (defaultVal) {
                setDeliveryTerms(defaultVal.Code);
            } else if (params.id) {
                setDeliveryTerms(null)
            }
        }
    }, [deliveryTermList, customer, offerDetails]);

    useEffect(() => {
        if (wayOfDeliveryList) {
            let defaultVal = null;
            if (offerDetails && params.id) {
                defaultVal = find(wayOfDeliveryList, {
                    Code: offerDetails.WayOfDelivery
                })
            }
            else {
                if (customer?.wayOfDelivery) {
                    defaultVal = find(wayOfDeliveryList, {
                        Code: customer?.wayOfDelivery
                    })
                } else if (wayOfDeliveryList.length > 0) {
                    defaultVal = wayOfDeliveryList[0]
                }
            }
            if (defaultVal) {
                setWayOfDelivery(defaultVal.Code);
            } else if (params.id) {
                setWayOfDelivery(null)
            }
        }
        if (stockPointConfig && offerDetails) {
            var stockpoint = offerDetails?.StockPointId ? offerDetails.StockPointId : stockPointConfig.defaultStockPointId;
            setStockPoint(stockpoint);
            setArticleRows(articleRows?.map(ar => ({...ar, stockPoint: ar.stockPoint ? ar.stockPoint : stockpoint})));
        }
    }, [wayOfDeliveryList, customer, offerDetails, stockPointConfig]);


    useEffect(() => {
        if (params.id && offerDetails) {
            let c = find(customerOptions, { customerNumber: offerDetails.CustomerNumber })
            if (c) {
                setCustomer(c);
                setPriceList(c.priceList);
            }
            setDeliveryTerms(offerDetails.TermsOfDelivery);
            setPaymentTerms(offerDetails.TermsOfPayment);
            setOfferDate(offerDetails.OfferDate ? new Date(offerDetails.OfferDate) : null);
            setExpireDate(offerDetails.ExpireDate ? new Date(offerDetails.ExpireDate) : null);
            let urRef = find(customerUserOptions, { name: offerDetails.YourReference })?.id
            let ourRef = find(tenantUserOptions, { name: offerDetails.OurReference })?.id
            setOurReference(ourRef ? ourRef : null);
            setYourReference(urRef ? urRef : null);
            let p = find(projectOptions, { projectNumber: offerDetails.Project })
            setProject(p ? p : null);
            let da = find(deliveryAddressList, { name: offerDetails?.DeliveryName })?.id
            setDeliveryAddress(da ? da : null);
            setDeliveryReference(offerDetails.DeliveryAddress1);
            setArticleRows(offerDetails?.OfferRows?.map(or => or.ArticleNumber ?
                {
                    article: or.Article,
                    quantity: or.Quantity,
                    price: or.Price,
                    discount: or.Discount,
                    total: or.Total,
                    stockPoint: or.StockPointId
                } :
                {
                    description: or.Description,
                    isTextRow: true,
                }
            ));
            setShipping(offerDetails.Freight);
        }
    }, [offerDetails]);

    useEffect(() => {
        if (projectOptions && offerDetails && params.id) {
            let p = find(projectOptions, { projectNumber: offerDetails.Project })
            setProject(p ? p : null);
        } else {
            setProject(null);
        }
    }, [projectOptions, offerDetails])

    useEffect(() => {
        if (deliveryAddressList && offerDetails && params.id) {
            let da = find(deliveryAddressList, { name: offerDetails?.DeliveryName })?.id
            setDeliveryAddress(da ? da : null);
        } else {
            setDeliveryAddress(null);
        }
    }, [deliveryAddressList, offerDetails]);


    useEffect(() => {
        setLoading(offerDetailsLoading)
    }, [offerDetailsLoading]);

    const clearErrors = () => setErrors([])

    const num = (val) => (val == "" ? 0 : val);

    const futureExpireDate = () => {
      let d = new Date()
      d.setDate(d.getDate() + 30)
      return d;
    }

    function onGetDeliveryAddressList() {
        setDeliveryAddressListLoading(true)
        get(`/api/delivery-address?customerId=${customer.id}`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setDeliveryAddressList(data?.data);
                }
                setDeliveryAddressListLoading(false)
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
                setDeliveryAddressListLoading(false)
            })
    }



    function onGetTermsOfPaymentList() {
        get(`/api/orders/termsofpayments`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setPaymentTermList(data?.data?.TermsOfPayments.map(p => (
                        {
                            ...p,
                            engdesc: p.Description.replace('dagar', props.t('days'))
                        }
                    )));
                }
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
            })
    }

    function onGetTermsOfDeliveryList() {
        get(`/api/orders/termsofdeliveries`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setDeliveryTermList(data?.data?.TermsOfDeliveries);
                }
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
            })
    }

    const onGetWayOfDeliveries = async () => {
        const response = await get(`/api/orders/wayofdeliveries`);
        if (response?.status === 200) {
            setWayOfDeliveryList(response.data.WayOfDeliveries)
        }
    }


    const onGetArticlePriceList = async (article) => {
        const response = await get(`/api/article/${article.id}/priceList/${customer.priceList}`);
        if (response?.status === 200) {
            return response.data?.Prices[0]?.Price;
        }

        return article.salesPrice;
    }

    async function updateArticleRow(articleRow, index) {
        clearErrors();
        let updatedArticleRows = [...articleRows]
        let price = await onGetArticlePriceList(articleRow.article)
        updatedArticleRows[index] = { ...articleRow, price }
        setArticleRows(updatedArticleRows)
    }

    const submitForm = async (e) => {
        e.preventDefault();
        if (!customer) {
            setErrors(["Please select a customer."])
            return;
        }
        if (!deliveryAddress) {
            setErrors(["Please select a delivery address."])
            return;
        }
        if (isEmpty(articleRows)) {
            setErrors(["Please add atleast one article."])
            return;
        }
        if (articleRows.filter((ar, i) => !ar.isTextRow && (!ar.article || !ar.quantity)).length > 0) {
            setErrors(["Please select article and quantity in article row."])
            return;
        }
        if (articleRows.filter((ar, i) => ar.isTextRow && !ar.description).length > 0) {
            setErrors(["Please enter description in text row."])
            return;
        }
        if(!yourReference){
            setErrors(["Please select your reference"])
            return;
        }
        if(!ourReference){
            setErrors(["Please select our reference"])
        }

        const orderRequest = {
            orderDetails: {
                Order: {
                    Project: project?.projectNumber,
                    YourReference: find(customerUserOptions, { id: parseInt(yourReference) })?.name,
                    DeliveryAddress1: find(deliveryAddressList, { id: parseInt(deliveryAddress) })?.address,
                    DeliveryAddress2: deliveryReference,
                    Freight: shipping
                }
            },
            deliveryAddressId: deliveryAddress,
        }

        setLoading(true); 
        try {
            const response = await post(`/api/offer/${params.id}/createOrder`, orderRequest);
            if(response.status === 200 && response.data.ErrorInformation == null){
                history.push(`/orders/${response.data.Offer.OrderReference}`)
            }
        } catch (e) {
            setErrors([props.t(API_ERR_MSG)]);
            setTimeout(() => setErrors([]), 5000);
        }
        
        setLoading(false);
    }

    function toggleAddCustomerUserModal(cu) {
        if(customerUserOptions && cu){
            setCustomerUserOptions([...customerUserOptions, cu]);
            setYourReference(cu.id);
        }
        setShowAddCustomerUserModal(!showAddCustomerUserModal)
    }

    function toggleAddDeliveryAddressModal(delAddress) {
        if(deliveryAddressList && delAddress){
            setDeliveryAddressList([...deliveryAddressList, delAddress]);
            setDeliveryAddress(delAddress.id);
        }
        setShowAddDeliveryAddressModal(!showAddDeliveryAddressModal)
    }

    const getDeliveryAddressLabel = (address) => {
        if(address){
            return address.name + " " + address.address + ", "+ address.city + " "+ address.zipCode + " " + address.country;
        }
        return "";
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={params.id ?
                            [{ title: props.t("Offers"), link: "/offers" }, { title: params.id, link: `/offers/${params.id}` }] :
                            [{ title: props.t("Offers"), link: "/offers" }]}
                        title={props.t("New Order")}
                    />
                    <Card>
                        <Loader loading={loading} />
                        {!loading && 
                        <AvForm>
                            <CardBody>
                                <CardTitle className="h4">{props.t("New Order")}</CardTitle>
                                {offerDetails?.OrderReference ?
                                <Row className="mt-3 text-center">
                                    <Col>
                                        <Alert color="danger">{props.t("Order already exists for the offer number " + params.id)}</Alert>
                                    </Col>
                                </Row> :
                                <div className="order-form-content">
                                    <Row>
                                        <Col>
                                            <Card className="border px-4 pb-3 pt-4">

                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                            >
                                                                {props.t("Customer")}
                                                            </label>
                                                            <SearchableDropdown
                                                                keyToDisplay={["name"]}
                                                                selectedValue={customer}
                                                                options={customerOptions ? customerOptions : []}
                                                                placeholder={props.t("Select customer")}
                                                                handleOnOptionClick={(co) => {
                                                                    clearErrors();
                                                                    setCustomer(co);
                                                                }}
                                                                disabled
                                                                required />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="priceList"
                                                                label={props.t("Price List")}
                                                                value={priceList}
                                                                className="form-control"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="deliveryTerms"
                                                                label={props.t("Delivery Terms")}
                                                                value={deliveryTerms}
                                                                className="form-control"
                                                                placeholder={props.t("")}
                                                                onChange={(e) => {
                                                                    clearErrors();
                                                                    setDeliveryTerms(e.target.value);
                                                                }}
                                                                type="select"
                                                                disabled
                                                            >
                                                                <option value={null}>{props.t("Select Delivery Terms")}</option>
                                                                {deliveryTermList.map((d, key) => <option value={d.Code}>{d.Description}</option>)}
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="paymentTerms"
                                                                label={props.t("Payment Terms")}
                                                                value={paymentTerms}
                                                                className="form-control"
                                                                placeholder={props.t("")}
                                                                onChange={(e) => {
                                                                    clearErrors();
                                                                    setPaymentTerms(e.target.value);
                                                                }}
                                                                type="select"
                                                                disabled
                                                            >
                                                                <option value={null}>{props.t("Select Payment Terms")}</option>
                                                                {paymentTermList.map((d, key) => <option value={d.Code}>{d.engdesc}</option>)}
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <label>{props.t("Stockpoint")}</label>
                                                            <DropdownSelect
                                                                defaultValue={{value : stockPoint, label : find(stockPoints, {id : stockPoint})?.name}}
                                                                values={stockPoint ? {value : stockPoint, label : find(stockPoints, {id : stockPoint})?.name} : null}
                                                                onChange={(e) => {
                                                                    setStockPoint(e?.value);
                                                                    setArticleRows(articleRows.map(ar => ({...ar, stockPoint: ar.stockPoint ? ar.stockPoint : e?.value})));
                                                                }}
                                                                placeholder={props.t("Select Stockpoint")}
                                                                disabled={loading}
                                                                options={stockPoints?.map(o => ({value: o.id, label: o.name}))}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className=""
                                                        >
                                                            {props.t("Offer Date")}
                                                        </label>
                                                        <div className="mb-3">
                                                            <DatePicker
                                                                onChange={(date) => {
                                                                    setErrors([]);
                                                                    setOfferDate(date);
                                                                }}
                                                                value={offerDate}
                                                                name={"offerDate"}
                                                                minDate={offerDate && offerDate < new Date() ? offerDate : new Date()}
                                                                format={"yyyy-MM-dd"}
                                                                placeholder={props.t("Enter Offer Date")}
                                                                dayPlaceholder={"dd"}
                                                                monthPlaceholder={"mm"}
                                                                yearPlaceholder={"yyyy"}
                                                                disabled />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className=""
                                                        >
                                                            {props.t("Expiry Date")}
                                                        </label>
                                                        <div className="mb-3">
                                                            <DatePicker
                                                                onChange={(date) => {
                                                                    setErrors([]);
                                                                    setExpireDate(date);
                                                                }}
                                                                value={expireDate}
                                                                disabled
                                                                name={"expireDate"}
                                                                minDate={expireDate && expireDate < new Date() ? expireDate : new Date()}
                                                                format={"yyyy-MM-dd"}
                                                                placeholder={props.t("Enter Expiry Date")}
                                                                dayPlaceholder={"dd"}
                                                                monthPlaceholder={"mm"}
                                                                yearPlaceholder={"yyyy"} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <label>{props.t("Our Reference")}</label>
                                                            <DropdownSelect
                                                                defaultValue={{value : ourReference, label : find(tenantUserOptions, {id : ourReference})?.name}}
                                                                values={ourReference ? {value : ourReference, label : find(tenantUserOptions, {id : ourReference})?.name} : null}
                                                                onChange={(e) => {
                                                                    setOurReference(e?.value);
                                                                }}
                                                                placeholder={props.t("Select Our Reference")}
                                                                disabled={!customer}
                                                                options={tenantUserOptions?.map(o => ({value: o.id, label: o.name}))}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                            >
                                                                {props.t("Project")}
                                                            </label>
                                                            <SearchableDropdown
                                                                keyToDisplay={["description"]}
                                                                selectedValue={project}
                                                                options={projectOptions}
                                                                placeholder={props.t("Select Project")}
                                                                handleOnOptionClick={(p) => setProject(p)}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Row>
                                                            <Col lg={10} className="pe-0">
                                                                <div className="mb-3">
                                                                    <label>{props.t("Your Reference")}</label>
                                                                    <DropdownSelect
                                                                        defaultValue={{value : yourReference, label : find(customerUserOptions, {id : yourReference})?.name}}
                                                                        values={yourReference ? {value : yourReference, label : find(customerUserOptions, {id : yourReference})?.name} : null}
                                                                        onChange={(e) => {
                                                                            setYourReference(e?.value);
                                                                        }}
                                                                        placeholder={props.t("Select Your Reference")}
                                                                        disabled={!customer}
                                                                        options={customerUserOptions?.map(o => ({value: o.id, label: o.name}))}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={2} className="d-flex align-items-end mb-3">
                                                                <div
                                                                    className="p-2 rounded"
                                                                    style={{
                                                                        border: "1px solid lightgrey",
                                                                        cursor: "pointer",
                                                                        backgroundColor: (customer) ? 'white' : '#f1f3f5'
                                                                    }}
                                                                    onClick={() => customer ? setShowAddCustomerUserModal(true) : null}
                                                                >
                                                                    <i className="uil uil-plus"></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="yourOrderNumber"
                                                                label={props.t("Your Order Number")}
                                                                value={yourOrderNumber}
                                                                onChange={(e) => setYourOrderNumber(e.target.value)}
                                                                className="form-control"
                                                                placeholder={props.t("Your Order Number")}
                                                                type="text"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <label>{props.t("Delivery Service")}</label>
                                                            <DropdownSelect
                                                                defaultValue={{value : wayOfDelivery, label : find(wayOfDeliveryList, {Code : wayOfDelivery})?.Description}}
                                                                values={wayOfDelivery ? {value : wayOfDelivery, label : find(wayOfDeliveryList, {Code : wayOfDelivery})?.Description} : null}
                                                                onChange={(e) => {
                                                                    clearErrors();
                                                                    setWayOfDelivery(e?.value);
                                                                }}
                                                                placeholder={props.t("Select Delivery Service")}
                                                                disabled={loading}
                                                                options={wayOfDeliveryList?.map(o => ({value: o.Code, label: o.Description}))}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Row>
                                                            <Col lg={10} className="pe-0">
                                                                <div className="mb-3">
                                                                    <label>{props.t("Delivery Address")}</label>
                                                                    <DropdownSelect
                                                                        defaultValue={{value : deliveryAddress, label : getDeliveryAddressLabel(find(deliveryAddressList, {id : deliveryAddress}))}}
                                                                        values={deliveryAddress ? {value : deliveryAddress, label : getDeliveryAddressLabel(find(deliveryAddressList, {id : deliveryAddress}))} : null}
                                                                        onChange={(e) => {
                                                                            clearErrors();
                                                                            setDeliveryAddress(e?.value);
                                                                        }}
                                                                        placeholder={props.t("Select Delivery Address")}
                                                                        disabled={loading}
                                                                        options={[
                                                                            { label: props.t("Project"), options: deliveryAddressList?.filter(d => d.projectId != null && d.projectId.id == project?.id).map(o => ({value: o.id, label: getDeliveryAddressLabel(o)}))},
                                                                            { label: props.t("Customer"), options: deliveryAddressList?.filter(d => d.projectId == null).map(o => ({value: o.id, label: getDeliveryAddressLabel(o)}))}]}
                                                                    />

                                                                </div>
                                                            </Col>
                                                            <Col lg={2} className="d-flex align-items-end mb-3">
                                                                <div
                                                                    className="p-2 rounded"
                                                                    style={{
                                                                        border: "1px solid lightgrey",
                                                                        cursor: "pointer",
                                                                        backgroundColor: (customer) ? 'white' : '#f1f3f5'
                                                                    }}
                                                                    onClick={() => customer ? setShowAddDeliveryAddressModal(true) : null}
                                                                >
                                                                    <i className="uil uil-plus"></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/*<div className="mb-3">
                                                            <AvField
                                                                name="deliveryAddress"
                                                                label={props.t("Delivery Address")}
                                                                value={deliveryAddress}
                                                                className="form-control"
                                                                placeholder={props.t("")}
                                                                onChange={(e) => {
                                                                    clearErrors();
                                                                    setDeliveryAddress(e.target.value);
                                                                }}
                                                                type="select"
                                                                disabled={loading}
                                                            >
                                                                <option value={null}>{props.t("Select Delivery Address")}</option>
                                                                {deliveryAddressList.map((d, key) => <option value={d.id}>{d.name}</option>)}
                                                            </AvField>
                                                        </div>*/}
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="deliveryReference"
                                                                label={props.t("Delivery Reference")}
                                                                value={deliveryReference}
                                                                onChange={(e) => setDeliveryReference(e.target.value)}
                                                                className="form-control"
                                                                placeholder={props.t("Delivery Reference")}
                                                                type="text"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className="border pt-4">
                                                <Row className="px-4">
                                                    <Col lg={3}>
                                                        <label htmlFor="example-text-input">{props.t("Article")}</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <label htmlFor="example-text-input">{props.t("Quantity")}</label>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <label htmlFor="example-text-input">{props.t("Stockpoint")}</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <label htmlFor="example-text-input">{props.t("Price")}</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <label htmlFor="example-text-input">{props.t("Discount") + " (%)"}</label>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <label htmlFor="example-text-input">{props.t("Total")}</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <label htmlFor="example-text-input">{props.t("Remove")}</label>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <label htmlFor="example-text-input">{props.t("Move")}</label>
                                                    </Col>
                                                </Row>
                                                {articleRows.map((ar, i) => ar.isTextRow ? 
                                                <Row key={i} className="px-4">
                                                    <Col lg={10}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="text"
                                                                value={ar.description}
                                                                className="form-control"
                                                                placeholder={props.t("Enter Text")}
                                                                type="text"
                                                                // onChange={(e) => updateArticleRow(
                                                                //     e.target.value, i, true)
                                                                // }
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="mb-3 d-flex align-items-center">
                                                            <i
                                                                className={`uil uil-trash-alt text-muted font-size-20 mx-1 px-2 py-1 lang-edit-icon`}
                                                                // onClick={() => handleRemoveArticleRow(i, true)}
                                                            ></i>
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="mb-3 d-flex align-items-center">
                                                            <i
                                                                className={`uil uil-arrow-circle-up text-muted font-size-20 me-1 px-2 py-1 lang-edit-icon`}
                                                                // onClick={() => handleMoveRowUp(i)}
                                                                title={i == 0 && props.t('First row cannot be moved up')}
                                                            ></i>
                                                            <i
                                                                className={`uil uil-arrow-circle-down text-muted font-size-20 px-2 py-1 lang-edit-icon`}
                                                                // onClick={() => handleMoveRowDown(i)}
                                                                title={i == articleRows.length - 1 && props.t('Last row cannot be moved down')}
                                                            ></i>
                                                        </div>
                                                    </Col>
                                                </Row> :
                                                <>
                                                    <Row key={i} className="px-4">
                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="text"
                                                                    value={ar.article?.articleNumber + " " +ar.article?.description}
                                                                    className="form-control"
                                                                    placeholder={props.t("Enter Text")}
                                                                    type="text"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={1}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="quantity"
                                                                    value={ar.quantity}
                                                                    className="form-control"
                                                                    placeholder={props.t("Quantity")}
                                                                    type="number"
                                                                    validate={{
                                                                        pattern: { value: '^[0-9]+$', errorMessage: 'Quantity must be integer numbers' },
                                                                    }}
                                                                    // onChange={(e) => updateArticleRow(
                                                                    //     {
                                                                    //         ...ar,
                                                                    //         quantity: e.target.value == "" ? 0 : e.target.value,
                                                                    //     }, i)
                                                                    // }
                                                                    disabled
                                                                    onWheel={preventNumberInputScroll}
                                                                    // onKeyDown={validateNumber}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="articleStockList"
                                                                    value={ar.stockPoint}
                                                                    className="form-control"
                                                                    placeholder={props.t("")}
                                                                    /*onChange={(e) => {
                                                                        updateArticleRow(
                                                                            {
                                                                                ...ar,
                                                                                stockPoint: e.target.value
                                                                            }, i)
                                                                    }}*/
                                                                    type="select"
                                                                    /*disabled={isDataLoading}*/
                                                                >
                                                                    <option value={null}>{props.t("Select Stockpoint")}</option>
                                                                    {stockPoints.map((sp, key) => <option value={sp.id}>{sp.name}</option>)}
                                                                </AvField>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="price"
                                                                    value={ar.price}
                                                                    className="form-control"
                                                                    placeholder={props.t("0")}
                                                                    validate={{
                                                                        pattern: { value: '^[0-9]+$', errorMessage: 'Price must be integer numbers' },
                                                                    }}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={1}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="discount"
                                                                    value={ar.discount}
                                                                    className="form-control"
                                                                    // placeholder={props.t("Discount")}
                                                                    type="number"
                                                                    validate={{
                                                                        pattern: { value: '^[0-9]+$', errorMessage: 'Discount must be integer numbers' },
                                                                    }}
                                                                    // onChange={(e) => updateArticleRow(
                                                                    //     {
                                                                    //         ...ar,
                                                                    //         discount: e.target.value == "" ? 0 : e.target.value,
                                                                    //     }, i)
                                                                    // }
                                                                    disabled
                                                                    onWheel={preventNumberInputScroll}
                                                                    // onKeyDown={validateNumber}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="total"
                                                                    value={ar.total}
                                                                    className="form-control"
                                                                    placeholder={props.t("0")}
                                                                    validate={{
                                                                        pattern: { value: '^[0-9]+$', errorMessage: 'Total must be integer numbers' },
                                                                    }}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={1}>
                                                            <div className="mb-3 d-flex align-items-center">
                                                                <i
                                                                    className={`uil uil-trash-alt text-muted font-size-20 mx-1 px-2 py-1 lang-edit-icon`}
                                                                    // onClick={() => handleRemoveArticleRow(i)}
                                                                ></i>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1}>
                                                            <div className="mb-3 d-flex align-items-center">
                                                                <i
                                                                    className={`uil uil-arrow-circle-up text-muted font-size-20 me-1 px-2 py-1 lang-edit-icon`}
                                                                    // onClick={() => handleMoveRowUp(i)}
                                                                    title={i == 0 && props.t('First row cannot be moved up')}
                                                                ></i>
                                                                <i
                                                                    className={`uil uil-arrow-circle-down text-muted font-size-20 px-2 py-1 lang-edit-icon`}
                                                                    // onClick={() => handleMoveRowDown(i)}
                                                                    title={i == articleRows.length - 1 && props.t('Last row cannot be moved down')}
                                                                ></i>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {(isStockable(ar.article) && ar.article.disposableQuantity <= 0 && parseInt(ar.quantity) > 0) &&
                                                        <Row>
                                                            <Col lg="3"></Col>
                                                            <Col lg="9">
                                                                <div className="d-flex px-2 mx-1 mb-3" style={{ marginTop: "-10px" }}>
                                                                    <span className="alert-danger border border-danger font-size-11 rounded p-1">
                                                                        {props.t("Out of Stock")}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>}
                                                    {(isStockable(ar.article) && ar.article.disposableQuantity > 0 && ar.article.disposableQuantity < parseInt(ar.quantity)) &&
                                                        <Row>
                                                            <Col lg="3"></Col>
                                                            <Col lg="9">
                                                                <div className="d-flex px-2 mx-1 mb-3" style={{ marginTop: "-10px" }}>
                                                                    <span className="alert-warning border border-warning font-size-11 rounded p-1">
                                                                        {props.t("Up to")} {parseInt(ar.article.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>}
                                                </>)}
                                                <Row className="px-4 pb-3">
                                                    <Col align="left">
                                                        <div className="d-flex mb-3">
                                                            <div>
                                                                <Button
                                                                    type="button"
                                                                    color="secondary"
                                                                    className="btn btn-success w-lg waves-effect waves-light me-3"
                                                                    // onClick={() => setArticleRows([...articleRows, newArticleRow])}
                                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                    disabled={true}
                                                                >
                                                                    {props.t("Add Article")}
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    type="button"
                                                                    color="secondary"
                                                                    className="btn btn-success w-lg waves-effect waves-light me-3"
                                                                    // onClick={() => setArticleRows([...articleRows, { description: "", isTextRow: true }])}
                                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                    disabled={true}
                                                                >
                                                                    {props.t("Add Text")}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col align="right">
                                            <div className="mb-1 d-flex justify-content-end">
                                                <Card className="border" style={{ minWidth: "350px" }}>
                                                    <div className="card-header bg-transparent d-flex justify-content-center">
                                                        <h5 className="font-size-16 mb-0">{props.t("Offer Summary")}</h5>
                                                    </div>
                                                    <CardBody className="p-4 pt-0">
                                                        <div className="table-responsive">
                                                            <Table className="table mb-0">
                                                                <tbody>
                                                                    <tr className="bt-0">
                                                                        <td className="pt-4">{props.t("Shipping")}:</td>
                                                                        <td className="text-end">
                                                                            <AvField
                                                                                name="shipping"
                                                                                value={shipping}
                                                                                className="form-control"
                                                                                placeholder={props.t("0")}
                                                                                onChange={(e) => setShipping(num(e.target.value))}
                                                                                validate={{
                                                                                    pattern: { value: '^[0-9]+$', errorMessage: 'Shipping must be integer numbers' },
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="pt-4">kr</td>
                                                                    </tr>
                                                                    <tr className="bt-0">
                                                                        <td>{props.t("Sub Total")} :</td>
                                                                        <td className="text-end">
                                                                            {parseInt(shipping) + parseInt(subTotal)}
                                                                        </td>
                                                                        <td>kr</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{props.t("Tax")} :</td>
                                                                        <td className="text-end">
                                                                            {parseInt(tax) + (parseInt(shipping) * 0.25)}
                                                                        </td>
                                                                        <td>kr</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{props.t("Total")} :</td>
                                                                        <td className="text-end">{parseInt(shipping) + (parseInt(shipping) * 0.25) + total}</td>
                                                                        <td>kr</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col align="right">
                                            <Button
                                                type="button"
                                                color="secondary"
                                                className="btn btn-secondary w-lg waves-effect waves-light me-3"
                                                onClick={() => history.push(`/offers/${params.id}`)}
                                                disabled={loading}
                                            >
                                                {props.t("Cancel")}
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="success"
                                                className="btn btn-success w-lg waves-effect waves-light"
                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                disabled={loading}
                                                onClick={(e) => submitForm(e)}
                                            >
                                                {props.t("Create Order")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 text-center">
                                        <Col>
                                            {errors.length > 0 ? (
                                                <Alert color="danger">{errors.map((msg, index) => index === 0 ? msg : (<><br />{props.t(msg)}</>))}</Alert>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>}
                            </CardBody>
                        </AvForm>}
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={showAddDeliveryAddressModal}
                backdrop="static"
                toggle={() => {
                    toggleAddDeliveryAddressModal();
                }}
                className="add-delivery-address-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Delivery Address")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            toggleAddDeliveryAddressModal();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <DeliveryAddressForm
                        location={props.location}
                        clearField={true}
                        useModal={true}
                        tog_standard={toggleAddDeliveryAddressModal}
                        customerId={customer?.id}
                    />
                </div>
            </Modal>
            <CustomerUserFormContainer
                useModal={true}
                modal_standard={showAddCustomerUserModal}
                setmodal_standard={setShowAddCustomerUserModal}
                tog_standard={toggleAddCustomerUserModal}
                customerId={customer?.id}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        customerOptions: state.Customer.customers,
        stockPoints: state.Config.stockPoints,
        stockPointConfig: state.Config.stockPointConfig,
        projects: state.Project.projects,
        customerUsers: state.Customer.customerUsers,
        tenantUserOptions: state.tenant.tenantAdmins,
        /*offerDetails: state.ecommerce.offerDetails?.data?.Offer,*/
        offerDetailsError: state.Offers.offerDetailsError,
        offerDetailsLoading: state.Offers.offerDetailsLoading,
        offerDetails: state.Offers.offerDetails,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetCustomerOptions: () => dispatch(GetCustomers(false)),
    onGetProjectOptions: (tenantId, customerId) => dispatch(getProjectList(customerId, null, tenantId)),
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId)),
    onGetStockPoints: () => dispatch(getStockPoints()),
    onGetStockPointConfig: () => dispatch(getStockPointConfig()),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
    onGetOfferDetails: id => dispatch(getOffersDetails(id)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(OrderFromOfferForm)));
